<template>
    <div>
        <p class="text-3xl mb-2">Configuración de correo</p>
        <a-card>
            <div class="my-2">
                <label for="email">Correo</label>
                <a-input id="email" v-model="form.email" />
            </div>
            <div class="flex justify-end mt-4">
                <a-button :loading="loading" outlined @click="handleUpdate">Actualizar</a-button>
            </div>
        </a-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    data: () => ({
        loading: false,
        form: {
            email: ''
        }
    }),
    computed: mapState({
        user: ({ session }) => session.user
    }),
    destroyed() {
        this.form = {}
    },
    methods: {
        ...mapActions({
            update: 'session/updateEmail'
        }),
        handleUpdate() {
            this.$confirm({
                auth: true,
                title: 'Confirmación',
                message: 'Ingrese su contraseña para proceder',
                button: {
                    yes: 'Proceder',
                    no: 'Cancelar'
                },
                callback: (confirm, password) => {
                    if (confirm) {
                        this.loading = !this.loading
                        this.update({
                            new_email: this.form.email,
                            password
                        })
                        .finally(() => {
                            this.loading = !this.loading
                        })
                    }
                }
            })
        }
    },
    mounted() {
        if (this.user.isTeacher || this.user.isCoordinator) {
            this.form = {
                ...this.user.teacher
            }
        }

        if (this.user.isAdmin) {
            this.form = {
                ...this.user
            }
        }

        if (this.user.isStudent) {
            this.form = {
                ...this.user.student
            }
        }
    }
}
</script>

<style scoped>

</style>