<template>
    <div>
        <p class="text-3xl mb-2">Configuración general</p>
        <a-card>
            <template v-if="user.isAdmin">
                <div class="my-2">
                    <label for="name">Nombre</label>
                    <a-input id="name" v-model="form.name" />
                </div>
            </template>

            <template v-else>
                <div class="my-2">
                    <label for="first_name">Nombre</label>
                    <a-input :error="error('first_name')" id="first_name" v-model="form.first_name" />
                </div>
                <div class="my-2">
                    <label for="last_name">Apellido</label>
                    <a-input :error="error('last_name')" id="last_name" v-model="form.last_name" />
                </div>
                <div class="my-2">
                    <label for="address">Dirección</label>
                    <a-input :error="error('address')" id="address" v-model="form.address" />
                </div>
                <div class="my-2">
                    <label for="phone">Teléfono</label>
                    <a-input :error="error('phone')" id="phone" v-model="form.phone" />
                </div>
                <div class="my-2">
                    <label for="mobile_phone">Teléfono celular</label>
                    <a-input :error="error('mobile_phone')" id="mobile_phone" v-model="form.mobile_phone" />
                </div>
                <div class="my-2" v-if="user.isStudent">
                    <label for="institution_email">Correo electrónico de la institución</label>
                    <a-input :error="error('institution_email')" id="institution_email" v-model="form.institution_email" />
                </div>
                <div class="my-2" v-if="user.isTeacher || user.isCoordinator">
                    <label for="profession">Profesión</label>
                    <a-input :error="error('profession')" id="profession" v-model="form.profession" />
                </div>

            </template>

            <div class="flex justify-end mt-4">
                <a-button outlined @click="handleUpdate(form)" :loading="loading">Actualizar</a-button>
            </div>
        </a-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    data: () => ({
        form: {},
        loading: false
    }),
    computed: {
        ...mapState({
            user: ({ session }) => session.user
        }),
        ...mapGetters(['error'])
    },
    methods: {
        ...mapActions({
            update: 'session/update'
        }),
        handleUpdate() {
            this.loading = !this.loading

            let form = {}

            Object.entries(this.form)
                .forEach($0 => {
                    if ($0[1]) {
                        form[$0[0]] = $0[1]
                    }
                })

            this.update(form)
                .finally(() => {
                    this.loading = !this.loading
                })
        },
        setUser(user) {
            if (user.isTeacher || user.isCoordinator) {
                this.form = {
                    ...user.teacher
                }
            }

            if (user.isAdmin) {
                this.form = {
                    ...user
                }
            }

            if (user.isStudent) {
                this.form = {
                    ...user.student
                }
            }
        }
    },
    destroyed() {
        this.form = {}
    },
    mounted() {
        this.setUser(this.user)
    }
}
</script>

<style scoped>

</style>