<template>
    <div>
        <p class="text-3xl mb-2">Configuración de contraseña</p>
        <a-card>
            <div class="my-2">
                <label for="new_password">Nueva contraseña</label>
                <a-input type="password" :error="error('new_password')" id="new_password" v-model="form.new_password" />
            </div>
            <div class="my-2">
                <label for="new_password_confirmation">Repita la nueva contraseña</label>
                <a-input type="password" :error="error('new_password_confirmation')" id="new_password_confirmation" v-model="form.new_password_confirmation" />
            </div>

            <div class="flex justify-end">
                <a-button outlined :loading="loading" @click="update" class="mt-4">Actualizar</a-button>
            </div>
        </a-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    data: () => ({
        loading: false,
        form: {
            new_password: '',
            new_password_confirmation: ''
        }
    }),
    computed: {
        ...mapGetters(['error']),
        ...mapState({
            user: ({ session }) => session.user
        })
    },
    methods: {
        ...mapActions({
            updatePassword: 'session/updatePassword'
        }),
        update() {
            this.$confirm({
                auth: true,
                title: 'Confirmación',
                message: 'Ingrese su contraseña para proceder',
                button: {
                    yes: 'Proceder',
                    no: 'Cancelar'
                },
                callback: (confirm, password) => {
                    if (confirm) {
                        this.loading = !this.loading
                        this.updatePassword({
                            ...this.form,
                            password
                        })
                        .finally(() => {
                            this.loading = !this.loading
                        })
                    }
                }
            })

        }
    }
}
</script>

<style scoped>

</style>