<template>
    <div>
        <div class="flex flex-wrap -mx-2">
            <div class="w-full md:w-1/2 lg:w-1/4 px-2">
                <a-card>
                    <div class="flex justify-end">
                        <label for="file_input">
                            <a-icon name="camera" class="-mt-4 cursor-pointer" v-tooltip="`Cambiar foto de perfil`" />
                        </label>
                        <input type="file" id="file_input" v-show="false" @change="update($event.target.files[0])">
                    </div>
                    <div class="flex flex-col items-center">
                        <a-avatar size="128" :picture="session.profile_picture" v-if="!loading" />
                        <template v-else>
                            <div class="shadow-md" :style="{
                                'background': `url(${session.profile_picture})`,
                                'background-size': '100%',
                                'display': 'flex',
                                'justify-content': 'center',
                                'align-items': 'center',
                                'width': '128px',
                                'height': '128px',
                                'border-radius': '50%'
                            }">
                                <div class="backdrop-animation">
                                    <div class="lds-roller">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <span class="font-bold text-xl mt-2">{{ fullLoggedUserName }}</span>
                    </div>
                </a-card>
            </div>
            <div class="w-full md:w-1/2 lg:w-3/4 px-2">
                <edit-general />
                <edit-email />
                <edit-password />
            </div>
        </div>
    </div>
</template>

<script>
import EditEmail from './partials/edit-email';
import EditPassword from './partials/edit-password';
import EditGeneral from './partials/edit-general';
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
    components: {
        EditEmail,
        EditPassword,
        EditGeneral
    },
    data: () => ({
        loading: false
    }),
    computed: {
        ...mapGetters({
            fullLoggedUserName: 'session/fullName'
        }),
        ...mapState({
            session: state => state.session.user,
            user: state => state.users.user
        })
    },
    methods: {
        ...mapActions({
            updatePicture: 'session/updatePicture'
        }),
        update(file) {
            this.loading = !this.loading
            this.updatePicture(file)
                .finally(() => this.loading = !this.loading)
        }
    }
}
</script>

<style scoped>

.backdrop-animation {
    background-color: rgba(0, 0, 0, .2);
    backdrop-filter: blur(5px);
    width: 128px;
    height: 128px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.transitioning-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 10;
    backdrop-filter: blur(20px);
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
