var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-wrap -mx-2"},[_c('div',{staticClass:"w-full md:w-1/2 lg:w-1/4 px-2"},[_c('a-card',[_c('div',{staticClass:"flex justify-end"},[_c('label',{attrs:{"for":"file_input"}},[_c('a-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Cambiar foto de perfil"),expression:"`Cambiar foto de perfil`"}],staticClass:"-mt-4 cursor-pointer",attrs:{"name":"camera"}})],1),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"type":"file","id":"file_input"},on:{"change":function($event){return _vm.update($event.target.files[0])}}})]),_c('div',{staticClass:"flex flex-col items-center"},[(!_vm.loading)?_c('a-avatar',{attrs:{"size":"128","picture":_vm.session.profile_picture}}):[_c('div',{staticClass:"shadow-md",style:({
                            'background': ("url(" + (_vm.session.profile_picture) + ")"),
                            'background-size': '100%',
                            'display': 'flex',
                            'justify-content': 'center',
                            'align-items': 'center',
                            'width': '128px',
                            'height': '128px',
                            'border-radius': '50%'
                        })},[_c('div',{staticClass:"backdrop-animation"},[_c('div',{staticClass:"lds-roller"},[_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div')])])])],_c('span',{staticClass:"font-bold text-xl mt-2"},[_vm._v(_vm._s(_vm.fullLoggedUserName))])],2)])],1),_c('div',{staticClass:"w-full md:w-1/2 lg:w-3/4 px-2"},[_c('edit-general'),_c('edit-email'),_c('edit-password')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }